import React, { useState } from 'react';

export default function TextInput({ label, Icon, onChange }) {
  const [textValue, setTextValue] = useState('');
  const [showWarning, setShowWarning] = useState(false);

  const handleChange = (e) => {
    const newValue = e.target.value;

    // Check if the input is '0' and the length of the input value is greater than 1
    if (newValue === '0' && newValue.length === 1) {
      // Show the warning if the input is '0' and the length of the input value is 1
      setShowWarning(true);
    } else if (newValue !== '0') {
      // If the input is not '0', update the state and call the onChange function
      setShowWarning(false);
      setTextValue(newValue.slice(0, 9));
      onChange(newValue.slice(0, 9));
    }
  };

  return (
    <div>
      <form className="group relative mb-4">
        <input
          className="focus:border-blue-400 focus:ring-blue-500 focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900  placeholder-slate-600 rounded-md py-4 pl-10 pr-2 ring-1 ring-slate-300 shadow-sm"
          type="number"
          placeholder={label}
          value={textValue}
          maxLength={9}
          onChange={handleChange}
        />
        <Icon className="h-5 w-5 flex-none text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" aria-hidden="true" />
      </form>
      {showWarning && <p style={{ color: 'red' }}>Please don't enter '0' as the first digit.</p>}
    </div>
  );
}
