// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [userId, setuserId] = useState(null);
    const [userPhone, setuserPhone] = useState(null);
    const [userData, setUserData] = useState('');

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        const userPhone = localStorage.getItem('userPhone');
        if (storedToken) {
            setToken(storedToken);
            setuserId(userId);
            setuserPhone(userPhone);

            // console.log('userId =>> ' + userId);
            // console.log('userPhone =>> ' + userPhone);
            // console.log('tokenString =>> ' + storedToken);
        }
    }, []);

    const login = (userToken, userId, userPhone) => {
        setToken(userToken);
        setuserId(userId);
        setuserPhone(userPhone);
        setUserData(userData);
        localStorage.setItem('userId', userId);
        localStorage.setItem('userPhone', userPhone);
        localStorage.setItem('token', userToken);
    };

    const logout = () => {
        setToken(null);

        localStorage.clear();
    };

    return (
        <AuthContext.Provider value={{ token, userId, userPhone, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
