import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './HomePage';
import Login from './LoginPage';
import { useAuth } from './contexts/AuthContext';

const App = () => {
  const { token } = useAuth();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const uniqueIdFromUrl = urlSearchParams.get('code');

    if (uniqueIdFromUrl) {
      clearCodeFromUrl();
      localStorage.setItem('scannedUniqueId', uniqueIdFromUrl);
    }
  }, []);

  const clearCodeFromUrl = () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Redirect to homepage if there's a code in the URL */}
          <Route path="/:code" element={<Navigate to="/" />} />

          {/* Login route */}
          <Route path="/login" element={token ? <Navigate to="/" /> : <Login />} />

          {/* Protected route: Homepage */}
          <Route path="/" element={token ? <HomePage /> : <Login />} />

        </Routes>
      </div>
    </Router>
  );
};

export default App;
