import React, { useState } from 'react';
import { Page, Tabbar, TabbarLink, Icon } from 'konsta/react';
import { GearAlt, HouseAlt } from 'framework7-icons/react';
import Home from './Home';
import SettingsPage from './SettingsPage';

export default function HomePage({ userData }) {
  const [activeTab, setActiveTab] = useState('tab-1');

  return (
    <Page>
      <Tabbar labels icons className="left-0 bottom-0 fixed">
        <TabbarLink
          active={activeTab === 'tab-1'}
          onClick={() => setActiveTab('tab-1')}
          icon={<Icon material={<HouseAlt className="w-8 h-8" />} />}
          label="Home"
        />
        <TabbarLink
          active={activeTab === 'tab-2'}
          onClick={() => setActiveTab('tab-2')}
          icon={<Icon material={<GearAlt className="w-8 h-8" />} />}
          label="Settings"
        />
      </Tabbar>

      {activeTab === 'tab-1' && <Home userData={userData} />}
      {activeTab === 'tab-2' && <SettingsPage userData={userData} />}
    </Page>
  );
}
