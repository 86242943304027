import React, { useState, useEffect } from 'react';
import {
    Page, Progressbar, Button, Icon, Block, Sheet, Notification
} from 'konsta/react';
import { Paperplane, Forward, Globe, Phone, Envelope, CheckmarkShieldFill, ArrowUpRight, } from 'framework7-icons/react';
import axios from 'axios';
import { useAuth } from './contexts/AuthContext';

export default function Home({ userData }) {
    const [notificationFull, setNotificationFull] = useState(false);
    const [notificationTitle, setNotificationTitle] = useState('');
    const [notificationDescription, setnotificationDescription] = useState('');
    const [loading, setLoading] = useState(true);
    const [sheetOpened, setSheetOpened] = useState(false);
    const [sheetCampaignOpened, setSheetCampaignOpened] = useState(false);
    const [shareSheetOpened, setShareSheetOpened] = useState(false);
    const [recentScans, setRecentScans] = useState([]);
    const [scanPoints, setScanPoints] = useState(0);
    const { token, userId } = useAuth();


    // campaign details 
    const [companyLogo, setCompanyLogo] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyDescription, setCompanyDescription] = useState('');
    const [companyEmail, setCompanyEmail] = useState('');
    const [companyPhone, setCompanyPhone] = useState('');
    const [companyWebsite, setCompanyWebsite] = useState('');
    const [campaignName, setCampaignName] = useState('');
    const [campaignDescription, setCampaignDescription] = useState('');
    const [campaignLink, setCampaignLink] = useState('');
    const [campaignPreviewImage, setCampaignPreviewImage] = useState('');
    const [campaignRewardPoint, setCampaignRewardPoint] = useState(0);

    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };


    const addScannedQrCode = async ({ userIdValue, advertId, accessToken }) => {
        try {
            // Check if geolocation is available
            const postData = {
                user_id: userIdValue,
                advert_id: advertId,

            };

            const response = await axios.post(`https://mywater-go-grpc-api-gateway.fly.dev/application/record_label_scan`, postData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            console.log(response.data);

            // Handle the response as needed
            if (response.data.status === 201) {
                setNotificationFull(true);
                setNotificationTitle("Scanned successfully");
                setnotificationDescription("Qr code successfully added");
                fetchData();
            } else {
                setNotificationFull(true);
                setNotificationTitle("Something went wrong");
                setnotificationDescription("You have already scanned this qr code");
                // openToast(setToastLeftOpened);
            }


        } catch (error) {
            // Handle errors
            console.error(error);
        }
    };

    useEffect(() => {
        let timer;
        if (notificationFull) {
            timer = setTimeout(() => {
                setNotificationFull(false);
            }, 3000);
        }
        fetchData();

        if (token) {
            setLoading(false);
        }

        const qrcode = localStorage.getItem('scannedUniqueId');

        console.log("accessToken ===> " + token)
        console.log("userId ===> ", userId)
        console.log("qrcode ...... ", qrcode);

        if (typeof qrcode === 'string') {
            addScannedQrCode({ userIdValue: userId, advertId: qrcode, accessToken: token });
            localStorage.removeItem('scannedUniqueId');
        }

        return () => clearTimeout(timer);
    }, [notificationFull, token, userId]);

    const fetchData = async () => {
        try {

            const apiEndpoint = `https://mywater-go-grpc-api-gateway.fly.dev/application/get_user_label_scan/${userId}`;

            const response = await axios.get(apiEndpoint, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            console.log(response.data);
            if (response.data.status == 200) {
                if (response.data.scans != null) {
                    setRecentScans(response.data.scans);
                    setScanPoints(response.data.total_points);
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <Page>
            <Notification
                opened={notificationFull}
                title="MyWater"
                titleRightText="now"
                subtitle={notificationTitle}
                text={notificationDescription}
            />

            <div className="bg-primary-blue relative h-[18rem] rounded-b-lg">
                <div className="absolute top-0 left-0 p-4">
                    <div className="flex">
                        <a href="#" >
                            <svg width="60" height="60" viewBox="0 0 207 117" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <mask id="mask0_1186_651" maskUnits="userSpaceOnUse" x="0" y="0"
                                    width="207" height="117">
                                    <path d="M0 0H206.938V116.276H0V0Z" fill="white" />
                                </mask>
                                <g mask="url(#mask0_1186_651)">
                                    <path
                                        d="M197.625 92.3112C185.406 104.525 165.688 104.77 153.167 93.0508C151.672 98.0977 148.938 102.723 145.115 106.551C132.641 119.02 112.354 119.02 99.8854 106.551L81.9323 88.5977L93.7604 76.7487L111.719 94.707C117.661 100.65 127.333 100.65 133.276 94.707C136.156 91.8268 137.74 88.0039 137.74 83.931C137.74 80.1185 136.354 76.5247 133.813 73.7227L115.281 55.1914L127.125 43.3268L164.234 80.4675C170.182 86.4102 179.849 86.4102 185.792 80.4675C188.672 77.5872 190.255 73.7591 190.255 69.6862C190.255 65.6133 188.672 61.7904 185.792 58.9102L148.708 21.8268C142.182 15.3008 131.552 15.3008 125.026 21.8268L54.5521 92.3008C48.5104 98.3477 40.474 101.676 31.9323 101.676C23.3906 101.676 15.3594 98.3477 9.31771 92.3112C3.28125 86.2695 -0.046875 78.2331 -0.046875 69.6966C-0.046875 61.1549 3.28125 53.1185 9.31771 47.082L46.401 9.9987C52.9271 3.47266 61.5 0.207031 70.0781 0.207031C78.6563 0.207031 87.2344 3.47266 93.7604 9.9987L103.464 19.7018L113.188 9.9987C119.719 3.47266 128.292 0.207031 136.87 0.207031C145.443 0.207031 154.021 3.47266 160.552 9.9987L197.63 47.082C203.672 53.1185 207 61.1497 207 69.6966C207 78.2383 203.661 86.2695 197.625 92.3112ZM81.9219 21.8372C75.3906 15.306 64.7656 15.306 58.2344 21.8372L21.1563 58.9206C18.276 61.7956 16.6927 65.6237 16.6927 69.6966C16.6927 73.7695 18.2813 77.5925 21.1563 80.4675C24.0365 83.3477 27.8646 84.931 31.9375 84.931C36.0104 84.931 39.8333 83.3477 42.7135 80.4675L58.2552 64.9258L58.1927 64.8633L91.6094 31.5247L81.9219 21.8372Z"
                                        fill="white" />
                                </g>
                            </svg>

                        </a>
                    </div>
                </div>
                <div className="absolute top-2 right-0 p-4">
                    <Icon
                        onClick={() => {
                            if (navigator.share) {
                                navigator
                                    .share({
                                        title: 'MyWater Agency',
                                        text: 'Check MyWater Agency, We\'re a leading Ugandan Ad-Tech startup, redefining corporate advertising with a unique platform that integrates branding, technology, and social responsibility.',
                                        url: 'https://app.mywater.agency',
                                    })
                                    .then(() => console.log('Successful share'))
                                    .catch((error) => console.log('Error sharing', error));
                            } else {
                                // Fallback for browsers that do not support navigator.share
                                console.log('Web Share API not supported');
                                alert('Sharing is not supported in this browser. Please use a different browser or share manually.');
                                // You can provide a fallback sharing mechanism here
                            }
                        }}
                        ios={<Forward className="w-7 h-7" />}
                        material={<Paperplane className="w-8 h-8" />}
                        className="text-white"
                    />
                </div>
                <div className="flex flex-col items-center justify-end h-full">
                    {/* <span className="text-2xl mb-8 text-white"><b>Hi</b> {userData.displayName}, you've </span> */}
                    <span className="text-6xl font-bold text-white">UGX {scanPoints}</span>
                    <p className="text-m text-white mt-2">Your main point balance</p>


                    <Block className="flex space-x-8 ">
                        <Button large tonal rounded onClick={() => {
                            if (scanPoints > 0) {
                                setSheetOpened(true)
                            } else {
                                setNotificationFull(true)
                                setNotificationTitle("No rewards to reedem")
                                setnotificationDescription("You do not have enough rewards to reedem. Please scan more codes to earn rewards.")
                            }
                        }}>Reedem rewards</Button>
                    </Block>
                    <Sheet
                        className="pb-safe w-full"
                        opened={sheetOpened}
                        onBackdropClick={() => setSheetOpened(false)}>

                        <h2 className="text-2xl font-bold p-4 text-primary-blue">Reedem Rewards</h2>
                        <p className="text-m px-4">
                            Reedem your rewards as airtime, data and SMS bundles.
                        </p>
                        <div className='flex flex-row justify-between px-4 py-8'>
                            <Button large tonal rounded onClick={() => {
                            }}>Airtime</Button>
                            <div className="px-1"></div>
                            <Button large tonal rounded onClick={() => {
                            }}>Data Bundle</Button>
                        </div>
                    </Sheet>
                    <Sheet
                        className="pb-safe w-full"
                        opened={sheetCampaignOpened}
                        onBackdropClick={() => setSheetCampaignOpened(false)}>
                        <h2 className="text-2xl font-bold p-4 text-primary-blue">Campaign Details</h2>

                        <div className='flex flex-row'>
                            {/* <img src={companyLogo} alt="Company Logo" className="w-18 h-18 mr-2 ml-4 rounded-3xl" /> */}
                            <div className="relative">
                                <img src={companyLogo} alt="Company Logo" className="w-18 h-18 mr-2 ml-4 rounded-3xl" />
                                {/* Position the icon on the top-right corner of the company logo */}

                                <Icon

                                    material={<CheckmarkShieldFill className="w-6 h-6 -mt-10 ml-18 bg-white  rounded-full" />}
                                    className="text-green-500"
                                />


                            </div>

                            <div className='flex flex-col'>
                                <p className="text-xl font-bold text-gray-500 px-4">
                                    {companyName}

                                </p>


                                <div className="text-container">
                                    <p className={`text-m px-4 w-72 ${showFullDescription ? '' : 'truncate'} text-ellipsis overflow-hidden`}>
                                        {companyDescription}
                                    </p>
                                    <button onClick={toggleDescription} className="see-more-btn text-blue-500 px-4 rounded mt-1">
                                        {showFullDescription ? 'See Less' : 'See More'}
                                    </button>
                                </div>
                            </div>
                        </div>


                        <div className='flex flex-row'>
                            <div className='top-2 right-0 ml-4 mt-4'>
                                <Icon
                                    ios={<Envelope className="w-4 h-4" />}
                                    material={<Envelope className="w-4 h-4" />}
                                    className="text-black"
                                />
                            </div>
                            <p className="text-m px-4 mt-3">
                                {companyEmail}
                            </p>

                        </div>

                        <div className='flex flex-row'>
                            <div className='top-2 right-0 ml-4 mt-4'>
                                <Icon
                                    ios={<Phone className="w-4 h-4" />}
                                    material={<Phone className="w-4 h-4" />}
                                    className="text-black"
                                />
                            </div>
                            <p className="text-m px-4 mt-3">
                                {companyPhone}
                            </p>

                        </div>



                        <div className='flex flex-row mt-2'>
                            <div className='top-4 right-0 ml-4 mt-1'>
                                <Icon
                                    ios={<Globe className="w-4 h-4" />}
                                    material={<Globe className="w-4 h-4" />}
                                    className="text-black"
                                />
                            </div>
                            <p className="text-m px-4">
                                {companyWebsite}
                            </p>

                        </div>


                        <div class="border-b-2 py-4 mr-4 ml-4 px-4"></div>

                        <div className='flex flex-row px-4 mt-6 mb-4 '>
                            <p className="text-xl  font-bold">
                                {campaignName}
                            </p>
                            <div className='mx-auto'></div>
                            <span className="inline-flex items-center rounded-2xl bg-blue-50 px-2 h-8 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                                Point {campaignRewardPoint} UGX
                            </span>
                        </div>

                        <p className="text-m mt-2 px-4">
                            {campaignDescription}
                        </p>

                        <a className=" text-blue-500 px-4 py-4" href={campaignLink}>
                            {campaignLink}
                            <Icon
                                ios={<ArrowUpRight className="w-4 h-4" />}
                                material={<ArrowUpRight className="w-4 h-4" />}
                                className="text-blue-500"
                            />
                        </a>

                        <img className="h-3/5 w-full rounded-2xl p-4" src={campaignPreviewImage} alt="Background" />

                    </Sheet>
                    <Sheet
                        className="pb-safe"
                        opened={shareSheetOpened}
                        onBackdropClick={() => setShareSheetOpened(false)}>

                        <div className="left" />
                     

                        <h2 className="text-2xl font-bold p-4 text-primary-blue">Share My Water</h2>

                        <p className="text-base p-4">
                            Earn points by scanning QR codes and reedem them for cash
                        </p>
                        <div className=" p-4">
                            <Progressbar progress={0.7} />
                        </div>
                        <Block>
                            <div className="mt-4">
                                <Button large onClick={() => setShareSheetOpened(false)}>Withdraw</Button>
                            </div>
                        </Block>
                    </Sheet>
                </div>
            </div>
            {recentScans.length > 0 && <div className="flex flex-col">
                <h2 className="text-2xl font-bold p-4 text-primary-blue">Recent Scans</h2>
            </div>}

            <div className="bg-white p-3 overflow-y-auto pb-32 h-2/4">
                <div className="flex flex-col">
                    {recentScans.map((scan, index) => (
                        <div key={index} className="border-1 border-stone-800 p-3 rounded-lg" onClick={() => {
                            setCompanyLogo(`${scan.company_logo}`);
                            setCompanyName(`${scan.company_name}`);
                            setCompanyDescription(`${scan.company_description}`);
                            setCompanyEmail(`${scan.company_email}`);
                            setCompanyPhone(`${scan.company_phone}`);
                            setCompanyWebsite(`${scan.company_website}`);
                            setCampaignName(`${scan.promotion_text}`);
                            setCampaignDescription(`${scan.promotion_description}`);
                            setCampaignLink(`${scan.advert_public_url}`);
                            setCampaignRewardPoint(scan.reward_point)
                            setCampaignPreviewImage(`${scan.picture_url}`);
                            setSheetCampaignOpened(true)
                        }}>
                            <div className='flex flex-row items-center'>
                                <img src={scan.company_logo} alt="Company Logo" className="w-18 h-18 mr-4 rounded-3xl" />
                                <div className="flex flex-col items-start justify-end h-full">
                                    <p className="text-lg font-bold">{scan.company_name}</p>
                                    <p className="text-m text-gray-500"> {scan.promotion_text}</p>
                                    {/* <p className="text-m text-gray-500"> {scan.promotion_description}</p> 
                                    */}
                                    <div className="text-m w-64 truncate text-ellipsis overflow-hidden">{scan.promotion_description}</div>

                                </div>
                                <div className='ml-auto'>
                                    <Icon
                                        onClick={() => {
                                            console.log(scan.advert_public_url);
                                            if (scan.advert_public_url && scan.advert_public_url !== '') {
                                                try {
                                                    const url = scan.advert_public_url;
                                                    window.open(url, '_blank');

                                                } catch (error) {
                                                    console.error('Error occurred while opening the URL:', error);
                                                }
                                            } else {
                                                alert("No public URL to show");
                                            }
                                        }}
                                        material={<ArrowUpRight className="w-7 h-7" />}
                                    />

                                </div>
                            </div>
                        </div>
                    ))}
                    {recentScans.length === 0 && <div className="flex flex-col mt-32 items-center justify-center h-full">
                        <p className="text-xl font-bold">No recent scans</p>
                        <p className="text-m py-2 text-gray-500">Scan a QR code to get started</p>
                    </div>}
                </div>
            </div>
        </Page>
    );
};