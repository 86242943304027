import React, { useState } from 'react';
import { Page, Navbar, Button, Block, BlockTitle } from 'konsta/react';
import { useAuth } from './contexts/AuthContext';

export default function SettingsPage() {
  const { userPhone } = useAuth();

  useState(() => {
  }, [userPhone]);

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'MyWater Agency',
          text: 'Check MyWater Agency, We\'re a leading Ugandan Ad-Tech startup, redefining corporate advertising with a unique platform that integrates branding, technology, and social responsibility.',
          url: 'https://app.mywater.agency',
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      // Fallback for browsers that do not support navigator.share
      console.log('Web Share API not supported');
      alert('Sharing is not supported in this browser. Please use a different browser or share manually.');
      // You can provide a fallback sharing mechanism here
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  return (
    <Page>
      <Navbar
        title="Settings"
        className="top-0 sticky"
        colors={{
          bgMaterial: 'bg-primary-blue',
          textMaterial: 'text-md-dark-on-surface'
        }}
        medium='Medium'
      />
      <div className="relative">
        <BlockTitle large colors={{ textMaterial: 'text-primary-blue' }}>Account</BlockTitle>
        <Block strong inset>
          <p className="text-base font-medium">+{userPhone}</p>
        </Block>
        <BlockTitle large colors={{ textMaterial: 'text-primary-blue' }}>Invite friends</BlockTitle>
        <Block strong inset className="space-y-4">
          <p className='text-base'>
            Invite friends and families to join MyWater and earn more rewards
          </p>
          <Button large
            colors={{ fillActiveBgMaterial: 'bg-primary-blue' }}
            onClick={handleShare}
          >
            Share App
          </Button>
        </Block>
        <div className='p-4'>
          <button
            className="w-full bg-primary-blue text-white py-4 rounded-lg font-semibold hover:bg-blue-600 transition-colors duration-300"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </div>
    </Page>
  );
}
